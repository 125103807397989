<template>
	<div class="admin-page">
		<Toast />
		<div>
			<div class="pt-5 grid">
				<h1 class="page-title">Administration générale</h1>
			</div>
			<div class="title-padding m-0">
				<h1 class="espace-header">Informations générales</h1>
			</div>
			<div v-if="partnerStore.getHealthCenter">
				<div class="formgrid grid">
					<div class="field col">
						<PInput
							label="Nom complet du centre de santé"
							:value="partnerStore.getHealthCenter.health_center_name" />
						<PInput
							label="Téléphone fixe"
							:value="partnerStore.getHealthCenter.mobile" />
						<PInput
							label="District"
							:value="partnerStore.getHealthCenter.district" />
						<PInput
							label="Adresse ligne 1"
							:value="partnerStore.getHealthCenter.address_1" />
						<PInput
							label="Adresse ligne 2"
							:value="partnerStore.getHealthCenter.address_2" />
					</div>
					<div class="field col">
						<PInput
							label="Trigramme"
							:value="partnerStore.getHealthCenter.trigramme" />
						<PInput
							label="Commune"
							:value="partnerStore.getHealthCenter.township" />
						<PInput
							label="Ville"
							:value="partnerStore.getHealthCenter.town" />
						<PInput
							label="Pays"
							:value="partnerStore.getHealthCenter.country" />
						<PInput
							label="BP"
							:value="partnerStore.getHealthCenter.postal_code" />
					</div>
				</div>
			</div>
			<div class="title-padding m-0">
				<h1 class="espace-header">Les services</h1>
			</div>
			<div class="justify-content-start my-3">
				<Button
					class="btn service-btn-class"
					@click="newService()">
					Ajouter un service
				</Button>
			</div>
			<div
				class="form-section mb-3"
				v-if="addService">
				<form @submit="onSubmit">
					<div class="formgrid grid mx-2 mb-0 mt-4">
						<div class="field col">
							<PInput
								label="Libellé"
								v-model="service_name"
								type="text"
								:class="{ 'p-invalid': errors.service_name }"
								field="service_name"
								:rules="serviceNameRules" />
						</div>
						<div class="field col">
							<PInput
								label="N° d'identifiant"
								v-model="identifier"
								field="identifier" />
						</div>
					</div>
					<div class="mb-4 add-agent-btn-container">
						<Button
							class="cancel-btn-class mx-2"
							@click="cancel">
							Annuler
						</Button>
						<Button
							class="add-agent-btn mx-2"
							label="Ajouter"
							type="submit" />
					</div>
				</form> 
			</div>
			<table-view
				:data="espacePartenaireStore().getServices"
				:columns="columns"
				:globalFilterFields="globalFilterFields"
				:tableTitle="`Liste de services`"
				tableType="services" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import { ref } from "vue";
	import { useForm } from "vee-validate";
	import * as yup from "yup";
	import PInput from "@/components/forms/p-input.vue";
	import { Service } from "@/models/service/service";
	import { useToast } from "primevue/usetoast";

	const toast = useToast()

	const schema = yup.object({
		service_name: yup.string().required("Le champ Libellé est requis").label("Libellé"),
		identifier: yup.string().label("N° d'identifiant"),
	});

	const { defineField, handleSubmit, resetForm, errors } = useForm({
		validationSchema: schema,
	});

	const [service_name] = defineField("service_name");
	const [identifier] = defineField("identifier");

	const serviceNameRules = {
		required: "Le nom du service est requis",
	};

	const onSubmit = handleSubmit((values) => {
		const service = {
			health_center_code: "",
			service_name: "",
			identifier: "",
		} as Service;
		service.health_center_code = useAuthStore().getUserInfo.health_center_code;
		service.service_name = values.service_name;
		service.identifier = values.identifier ? values.identifier : "";
		espacePartenaireStore()
			.addNewService(service)
			.finally(() => {
				toast.add({ severity: 'success', summary: 'Message', detail: "Ajout du service effectué avec succès!", life: 8000 });
				addService.value = !addService.value;
				cancel();
			});
	});

	const addService = ref(false);

	const cancel = () => {
		addService.value = false;
		resetForm();
	};

	const newService = () => {
		addService.value = !addService.value;
		resetForm();
	};
</script>

<script lang="ts">
	import { defineComponent } from "vue";
	import TableView from "@/components/table/table-view/table-view.vue";
	import { espacePartenaireStore } from "@/store/modules/espace-partenaire";
	import { useAuthStore } from "@/store/modules/auth";
	export default defineComponent({
		name: "AdminPage",
		components: {
			TableView,
		},
		data() {
			return {
				small_screen: false,
				data: false,
				columns: [
					{ field: "service_name", header: "Nom du service" },
					{ field: "identifier", header: "N° d'identifiant" },
					{ field: "created_at", header: "Date de création" },
				],
				globalFilterFields: ["service_name"],
				servicesData: [],
				partnerStore: espacePartenaireStore(),
				authStore: useAuthStore(),
			};
		},
		beforeMount() {
			this.partnerStore.loadServices();
		},
	});
</script>
<style lang="scss" src="./admin-page.scss" scoped />
