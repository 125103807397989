import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex-auto", _ctx.inline ? 'input-group' : ''])
  }, [
    _createVNode(_component_Dropdown, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      editable: _ctx.editable,
      options: _ctx.options,
      optionLabel: _ctx.optionLabel,
      optionValue: _ctx.optionValue,
      placeholder: _ctx.placeholder,
      showClear: _ctx.showClear,
      onChange: _ctx.onChange,
      style: {"width":"200px"}
    }, null, 8, ["modelValue", "editable", "options", "optionLabel", "optionValue", "placeholder", "showClear", "onChange"])
  ], 2))
}