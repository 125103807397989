import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, isRef as _isRef, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-container"
}
const _hoisted_3 = { class: "title-padding m-0" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "form-section" }
const _hoisted_6 = { class: "formgrid grid mx-2 mt-6" }
const _hoisted_7 = { class: "field col" }
const _hoisted_8 = { class: "field grid px-2" }
const _hoisted_9 = {
  class: "col-12 md:col",
  style: {"text-align":"end"}
}
const _hoisted_10 = {
  id: "type-help",
  class: "p-error"
}
const _hoisted_11 = { class: "field col" }
const _hoisted_12 = { class: "field grid px-2" }
const _hoisted_13 = {
  class: "col-12 md:col",
  style: {"text-align":"end"}
}
const _hoisted_14 = {
  id: "type-help",
  class: "p-error"
}
const _hoisted_15 = { class: "form-section" }
const _hoisted_16 = { class: "formgrid grid mx-2 mt-6" }
const _hoisted_17 = { class: "field col" }
const _hoisted_18 = { class: "field col" }
const _hoisted_19 = { class: "field grid px-2 py-0" }
const _hoisted_20 = {
  class: "col-12 md:col",
  style: {"text-align":"end"}
}
const _hoisted_21 = {
  id: "type-help",
  class: "p-error"
}
const _hoisted_22 = {
  class: "flex py-0 my-0",
  style: {"justify-content":"end"}
}
const _hoisted_23 = {
  id: "type-help",
  class: "p-error"
}
const _hoisted_24 = { class: "field grid px-2 py-1" }
const _hoisted_25 = {
  class: "col-12 md:col flex flex-wrap gap-3",
  style: {"justify-content":"end"}
}
const _hoisted_26 = { class: "flex" }
const _hoisted_27 = { class: "flex" }
const _hoisted_28 = {
  id: "type-help",
  class: "p-error"
}
const _hoisted_29 = { class: "field grid px-2 py-1" }
const _hoisted_30 = {
  class: "col flex",
  style: {"justify-content":"start"}
}
const _hoisted_31 = { class: "my-4 send-declaration-btn-container" }
const _hoisted_32 = { key: 2 }
const _hoisted_33 = { class: "mt-4" }
const _hoisted_34 = {
  key: 3,
  class: "bg-default-green tracked_code_container"
}
const _hoisted_35 = { class: "text-white tracked_text" }
const _hoisted_36 = { class: "code-color" }
const _hoisted_37 = { class: "mt-4 send-declaration-btn-container" }

import { ref, watch } from "vue";
	import { useForm } from "vee-validate";
	import * as yup from "yup";
	import { useRouter } from 'vue-router';
	import { useToast } from "primevue/usetoast";
	import ErrorManager from '../../errorManager';

	
	import { defineComponent } from "vue";
	import PInput from "@/components/forms/p-input.vue";
	import { BirthDeclaration } from "@/models/declaration/birth-declaration";
	import { useAuthStore } from "@/store/modules/auth";
	import { espacePartenaireStore } from "@/store/modules/espace-partenaire";
	import { useBirthStore } from "@/store/modules/birth";
	import { agentStore } from "@/store/modules/agent";
	import { AgentOption } from "@/models/agent/agent";
	import { birthTemplateId } from "@/app.config";
	import { dataMixin } from "@/mixins/data.mixins";
	import { dateMixin } from "@/mixins/date.mixins";
    import { usePrintStore } from "@/store/modules/print-process";
	
	const __default__ = defineComponent({
		name: "BirthPage",
		components: { PInput },
		mixins: [dataMixin, dateMixin],
		data() {
			const minDate = new Date();
			minDate.setDate(minDate.getDate() - 44);

			const maxDate = new Date();
			return {
				maxDate: maxDate,
				minDate: minDate,
				autoFilteredValue: [] as Array<object>,
				authStore: useAuthStore(),
				partnerStore: espacePartenaireStore(),
				agentStore: agentStore(),
			};
		},
		methods: {
			
			searchUser(event) {
				if (!event.query.trim().length) {
					this.autoFilteredValue = [...this.agentStore.agentOptions];
				} else {
					this.autoFilteredValue = this.agentStore.agentOptions.filter((user) => {
						return user.name.toLowerCase().includes(event.query.toLowerCase());
					});
				}
			},
			loadAgents() {
				if(!this.agentStore.getAgents) this.agentStore.loadAgents();
			}
		},
		beforeMount() {
			this.loadAgents()
			if(!usePrintStore().getBirthPrintPage) usePrintStore().loadPrintPage(birthTemplateId, 'birth')
		},
	});


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

	const toast = useToast()
	
	const schema = yup.object({
		midwife: yup
			.string()
			.required("Veuillez choisir l'accoucheur(se)")
			.label("Accoucheur (se)"),
		secret_code: yup
			.string()
			.required("Le code secret est requis")
			.label("Code secret"),
		declarant: yup
			.string()
			.required("Veuillez choisir le déclarant")
			.label("Déclarant"),
		mother_first_name: yup
			.string()
			.matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/, {
				message: "Le champ ne doit contenir que des lettres"
			})
			.required("Le nom de la mère est requis")
			.label("Nom de la mère"),
		mother_last_name: yup
			.string()
			.matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/, {
				message: "Le champ ne doit contenir que des lettres"
			})
			.required("Le prénom de la mère est requis")
			.label("Prénoms de la mère"),
		mother_phone_number: yup
			.string()
			.required("Le téléphone de la mère est requis")
			.matches(
				/^[0-9]{8}$/,
				"Le numéro de téléphone doit contenir exactement 8 chiffres"
			)
			.label("Téléphone de la mère"),
		other_contact_1: yup
			.string()
			.required("Veuillez renseigner un deuxieme numero")
			.matches(
				/^[0-9]{8}$/,
				"Le numéro de téléphone doit contenir exactement 8 chiffres"
			)
			.label("Autre contact 1"),
		birthdate: yup
			.string()
			.required("La date d'accouchement est requise")
			.label("Date d'accouchement"),
		birth_hour: yup
			.string()
			.required("L'heure d'accouchement est requise")
			.label("Heure d'accouchement"),
		sex: yup
			.string()
			.required("Veuillez choisir le sexe de l'enfant")
			.label("Sexe de l'enfant"),
		neighborhood: yup
			.string()
			.required("Veuillez renseigner le quartier de la mère")
			.label("Quartier de la mère"),
		address: yup
			.string()
			.required("Veuillez renseigner l'adresse de la mère")
			.label("Adresse de la mère"),
	});

	const { defineField, handleSubmit, errors } = useForm({
		validationSchema: schema,
	});

	const [midwife] = defineField("midwife");
	const [secret_code] = defineField("secret_code");
	const [declarant] = defineField("declarant");
	const [mother_first_name] = defineField("mother_first_name");
	const [mother_last_name] = defineField("mother_last_name");
	const [mother_phone_number] = defineField("mother_phone_number");
	const [other_contact_1] = defineField("other_contact_1");
	const [other_contact_2] = defineField("other_contact_2");
	const [birthdate] = defineField("birthdate");
	const [birth_hour] = defineField("birth_hour");
	const [sex] = defineField("sex");
	const [neighborhood] = defineField("neighborhood");
	const [address] = defineField("address");
	const [stillborn] = defineField("stillborn");

	const midwife_select = ref({} as AgentOption);
	const declarant_select = ref({} as AgentOption);
	const submitted = ref(false);
	const isLoading = ref(false);
	const tracked_code = ref('');
	const dataResponse = ref(null);
	const router = useRouter();

	watch(
		() => midwife_select.value,
		(newVal) => {
			if(newVal?.id) midwife.value = newVal.id;
			else midwife.value = " "
		}
	);

	watch( 
		() => declarant_select.value,
		(newVal) => {
			declarant.value = newVal.id;
		}
	);

	const onSubmit = handleSubmit((values) => {
		if (
			isLoading.value ||
			new Date(values.birthdate) > new Date()
		) {
			toast.add({ severity: 'error', summary: 'Message', detail: 'Veuillez renseigner une date de naissance valide', life: 8000 });
			return;
		}
		isLoading.value = true;
		const birthDeclaration = values as BirthDeclaration;
		birthDeclaration.agent_function = 'Agent'
		birthDeclaration.other_contact_2 = values.other_contact_2 ? values.other_contact_2 : '';
		birthDeclaration.health_center = useAuthStore().getUserInfo.health_center;
		birthDeclaration.health_center_code = useAuthStore().getUserInfo.health_center_code;
		birthDeclaration.birthdate = dateMixin.methods.resetTimeToZero(values.birthdate);
		birthDeclaration.stillborn = values.stillborn;

		const array = [] as Array<BirthDeclaration>;
		array.push(birthDeclaration);
		useBirthStore().sendBirthDeclaration(array)
			.then((response) => {
				submitted.value = true;
				tracked_code.value = response.tracked_code;
				dataResponse.value = response;
				toast.add({ severity: 'success', summary: 'Message', detail: "Votre document a été généré avec succès. Veuillez l'imprimer dans le nouvel onglet!", life: 8000 });
				generateDoc(response);
			})
			.catch((error) => {
				const errorMessage = ErrorManager.getErrorMessage();
				toast.add({ severity: 'error', summary: 'Message', detail: errorMessage, life: 8000 });
				submitted.value = false;
			})
			.finally(() => {
				isLoading.value = false;
			});
	});

	const generateDoc = (response: BirthDeclaration) => {
		const variables = {
			district: espacePartenaireStore().getHealthCenter.district,
			township: espacePartenaireStore().getHealthCenter.township,
			health_center_name: response.health_center,
			bp: espacePartenaireStore().getHealthCenter.postal_code,
			tracked_code: response.tracked_code,
			midwife: dataMixin.methods.agentName(response.midwife),
			function: dataMixin.methods.agentFunction(response.midwife),
			mother_lastname: response.mother_last_name,
			mother_firstname: response.mother_first_name,
			neighborhood: response.neighborhood,
			address: response.address,
			birth_date: dateMixin.methods.formatDate(response.birthdate),
			birth_hour: response.birth_hour,
			sex: response.sex == "male" ? "Masculin" : "Feminin",
			town: espacePartenaireStore().getHealthCenter.town,
			created_at: dateMixin.methods.formatDate(response.created_at),
			center_phone: espacePartenaireStore().getHealthCenter.mobile,
			service: dataMixin.methods.agentService(response.midwife),
		};
		const windowPrint = window.open('', '', 'width=800,height=600');
		windowPrint?.document.write(processTemplate(
			usePrintStore().getBirthPrintPage, 
			{
				...variables
			}
		));
		windowPrint?.document.close();
		windowPrint?.focus();
		windowPrint?.print();
	};
	const processTemplate = (template: string, variables: Record<string, string>) => {
		return template.replace(/{{\s*(\w+)\s*}}/g, (_, variable) => {
			return variables[variable] || '';
		});
	};
	const goToHome = (type: boolean) => {
		if (type) {
			router.push({ path: "/accueil" });
		} else {					
			midwife_select.value = {};
			secret_code.value = "",
			mother_first_name.value = "",
			mother_last_name.value = "",
			mother_phone_number.value = "",
			other_contact_1.value = "",
			other_contact_2.value = "",
			birthdate.value = " ",
			birth_hour.value = " ",
			sex.value = " ",
			neighborhood.value = "",
			address.value = "",
			stillborn.value = false;

			submitted.value = false;
			dataResponse.value = null;
		}
	};


return (_ctx: any,_cache: any) => {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Toast),
    _createElementVNode("div", null, [
      (isLoading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ProgressSpinner, {
              style: {"width":"50px","height":"50px"},
              strokeWidth: "5",
              fill: "var(--surface-ground)",
              animationDuration: ".5s",
              "aria-label": "Custom ProgressSpinner"
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
          class: "flex arrow_back_div mb-4"
        }, _cache[22] || (_cache[22] = [
          _createElementVNode("span", { class: "material-icons arrow_back_class mr-2" }, "arrow_back", -1),
          _createElementVNode("span", { class: "form-header" }, "Retour", -1)
        ])),
        _cache[23] || (_cache[23] = _createElementVNode("h1", { class: "espace-header" }, "Déclaration d'une naissance", -1))
      ]),
      _cache[35] || (_cache[35] = _createElementVNode("div", { class: "form-header" }, " Ce formulaire doit être rempli par un professionnel de la santé affilié à la télédéclaration ", -1)),
      (!submitted.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("form", {
              onSubmit: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)))
            }, [
              _createElementVNode("div", _hoisted_5, [
                _cache[26] || (_cache[26] = _createElementVNode("div", { class: "section-header" }, "Informations du centre de santé", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(PInput, {
                      label: "Centre de santé",
                      value: _unref(useAuthStore)().getUserInfo.health_center,
                      disabled: ""
                    }, null, 8, ["value"]),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[24] || (_cache[24] = _createElementVNode("label", {
                        for: "accoucheuse",
                        class: "col-12 mb-2 md:col md:mb-0"
                      }, "Accoucheur (se)", -1)),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_AutoComplete, {
                          class: "w-full",
                          placeholder: "Selectionnez...",
                          id: "id",
                          dropdown: true,
                          multiple: false,
                          modelValue: midwife_select.value,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((midwife_select).value = $event)),
                          suggestions: _ctx.autoFilteredValue,
                          onComplete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchUser($event))),
                          field: "name"
                        }, null, 8, ["modelValue", "suggestions"]),
                        _createElementVNode("small", _hoisted_10, _toDisplayString(_unref(errors).midwife), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _cache[25] || (_cache[25] = _createElementVNode("label", {
                        for: "declarant",
                        class: "col-12 mb-2 md:col md:mb-0"
                      }, "Déclarant", -1)),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_AutoComplete, {
                          class: "w-full",
                          placeholder: "Selectionnez...",
                          id: "value",
                          dropdown: true,
                          multiple: false,
                          modelValue: declarant_select.value,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((declarant_select).value = $event)),
                          suggestions: _ctx.autoFilteredValue,
                          onComplete: _cache[4] || (_cache[4] = ($event: any) => (_ctx.searchUser($event))),
                          field: "name"
                        }, null, 8, ["modelValue", "suggestions"]),
                        _createElementVNode("small", _hoisted_14, _toDisplayString(_unref(errors).declarant), 1)
                      ])
                    ]),
                    _createVNode(PInput, {
                      label: "Code secret",
                      autocomplete: "off",
                      value: _unref(secret_code),
                      modelValue: _unref(secret_code),
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(secret_code) ? (secret_code).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).secret_code }),
                      field: "secret_code"
                    }, null, 8, ["value", "modelValue", "class"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _cache[32] || (_cache[32] = _createElementVNode("div", { class: "section-header" }, "Informations sur la naissance", -1)),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(PInput, {
                      label: "Nom de la mère",
                      value: _unref(mother_last_name),
                      modelValue: _unref(mother_last_name),
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(mother_last_name) ? (mother_last_name).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).mother_last_name }),
                      field: "mother_last_name"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createVNode(PInput, {
                      label: "Téléphone de la mère",
                      value: _unref(mother_phone_number),
                      modelValue: _unref(mother_phone_number),
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(mother_phone_number) ? (mother_phone_number).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).mother_phone_number }),
                      field: "mother_phone_number"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createVNode(PInput, {
                      label: "Autre contact 1",
                      value: _unref(other_contact_1),
                      modelValue: _unref(other_contact_1),
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_isRef(other_contact_1) ? (other_contact_1).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).other_contact_1 }),
                      field: "other_contact_1"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createVNode(PInput, {
                      label: "Autre contact 2",
                      value: _unref(other_contact_2),
                      modelValue: _unref(other_contact_2),
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_isRef(other_contact_2) ? (other_contact_2).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).other_contact_2 }),
                      field: "other_contact_2"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createVNode(PInput, {
                      label: "Quartier de la mère",
                      value: _unref(neighborhood),
                      modelValue: _unref(neighborhood),
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_isRef(neighborhood) ? (neighborhood).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).neighborhood }),
                      field: "neighborhood"
                    }, null, 8, ["value", "modelValue", "class"])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(PInput, {
                      label: "Prénoms de la mère",
                      value: _unref(mother_first_name),
                      modelValue: _unref(mother_first_name),
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_isRef(mother_first_name) ? (mother_first_name).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).mother_first_name }),
                      field: "mother_first_name"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createElementVNode("div", _hoisted_19, [
                      _cache[27] || (_cache[27] = _createElementVNode("label", {
                        for: "date",
                        class: "col-12 mb-2 md:col md:mb-0"
                      }, "Date d'accouchement", -1)),
                      _createElementVNode("div", _hoisted_20, [
                        _createVNode(_component_Calendar, {
                          class: "w-full",
                          showIcon: "",
                          modelValue: _unref(birthdate),
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_isRef(birthdate) ? (birthdate).value = $event : null)),
                          minDate: _ctx.minDate,
                          maxDate: _ctx.maxDate,
                          showOnFocus: false,
                          dateFormat: "dd/mm/yy"
                        }, null, 8, ["modelValue", "minDate", "maxDate"]),
                        _createElementVNode("small", _hoisted_21, _toDisplayString(_unref(errors).birthdate), 1)
                      ])
                    ]),
                    _createVNode(PInput, {
                      label: "Heure d'accouchement",
                      type: "time",
                      value: _unref(birth_hour),
                      modelValue: _unref(birth_hour),
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_isRef(birth_hour) ? (birth_hour).value = $event : null))
                    }, null, 8, ["value", "modelValue"]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("small", _hoisted_23, _toDisplayString(_unref(errors).birth_hour), 1)
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _cache[30] || (_cache[30] = _createElementVNode("label", {
                        for: "sexe",
                        class: "col-12 mb-2 md:col md:mb-0"
                      }, "Sexe de l'enfant", -1)),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("div", _hoisted_26, [
                          _createVNode(_component_RadioButton, {
                            inputId: "male",
                            name: "Masculin",
                            value: "male",
                            modelValue: _unref(sex),
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_isRef(sex) ? (sex).value = $event : null))
                          }, null, 8, ["modelValue"]),
                          _cache[28] || (_cache[28] = _createElementVNode("label", {
                            for: "male",
                            class: "ml-2"
                          }, "Masculin", -1))
                        ]),
                        _createElementVNode("div", _hoisted_27, [
                          _createVNode(_component_RadioButton, {
                            inputId: "female",
                            name: "Feminin",
                            value: "female",
                            modelValue: _unref(sex),
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (_isRef(sex) ? (sex).value = $event : null))
                          }, null, 8, ["modelValue"]),
                          _cache[29] || (_cache[29] = _createElementVNode("label", {
                            for: "female",
                            class: "ml-2"
                          }, "Feminin", -1))
                        ]),
                        _createElementVNode("small", _hoisted_28, _toDisplayString(_unref(errors).sex), 1)
                      ])
                    ]),
                    _createVNode(PInput, {
                      label: "Adresse de la mère",
                      value: _unref(address),
                      modelValue: _unref(address),
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_isRef(address) ? (address).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).address }),
                      field: "address"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createElementVNode("div", _hoisted_29, [
                      _cache[31] || (_cache[31] = _createElementVNode("label", {
                        for: "sexe",
                        class: "col mb-2 md:col md:mb-0"
                      }, "Cet enfant est-il mort né ?", -1)),
                      _createElementVNode("div", _hoisted_30, [
                        _createVNode(_component_Checkbox, {
                          modelValue: _unref(stillborn),
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_isRef(stillborn) ? (stillborn).value = $event : null)),
                          inputId: "stillborn",
                          name: "stillborn",
                          binary: ""
                        }, null, 8, ["modelValue"])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_Button, {
                  class: "send-declaration-btn",
                  label: "Ajouter",
                  type: "submit"
                })
              ])
            ], 32)
          ]))
        : _createCommentVNode("", true),
      (submitted.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("button", {
                class: "files-btn-class mr-2",
                onClick: _cache[19] || (_cache[19] = ($event: any) => (goToHome(true)))
              }, " Accueil "),
              _createElementVNode("button", {
                class: "files-btn-class",
                onClick: _cache[20] || (_cache[20] = ($event: any) => (goToHome(false)))
              }, " Déclarer une autre naissance ")
            ])
          ]))
        : _createCommentVNode("", true),
      (submitted.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _cache[33] || (_cache[33] = _createTextVNode(" Votre déclaration est enregistrée sous le code : ")),
              _createElementVNode("span", _hoisted_36, _toDisplayString(tracked_code.value), 1),
              _cache[34] || (_cache[34] = _createTextVNode(" . "))
            ]),
            _createElementVNode("div", _hoisted_37, [
              (dataResponse.value)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "send-declaration-btn",
                    onClick: _cache[21] || (_cache[21] = ($event: any) => (generateDoc(dataResponse.value)))
                  }, " Télécharger l'attestation "))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})