import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, isRef as _isRef, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "admin-page" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "formgrid grid" }
const _hoisted_4 = { class: "field col" }
const _hoisted_5 = { class: "field col" }
const _hoisted_6 = { class: "justify-content-start my-3" }
const _hoisted_7 = {
  key: 1,
  class: "form-section mb-3"
}
const _hoisted_8 = { class: "formgrid grid mx-2 mb-0 mt-4" }
const _hoisted_9 = { class: "field col" }
const _hoisted_10 = { class: "field col" }
const _hoisted_11 = { class: "mb-4 add-agent-btn-container" }

import { ref } from "vue";
	import { useForm } from "vee-validate";
	import * as yup from "yup";
	import PInput from "@/components/forms/p-input.vue";
	import { Service } from "@/models/service/service";
	import { useToast } from "primevue/usetoast";

	
	import { defineComponent } from "vue";
	import TableView from "@/components/table/table-view/table-view.vue";
	import { espacePartenaireStore } from "@/store/modules/espace-partenaire";
	import { useAuthStore } from "@/store/modules/auth";
	const __default__ = defineComponent({
		name: "AdminPage",
		components: {
			TableView,
		},
		data() {
			return {
				small_screen: false,
				data: false,
				columns: [
					{ field: "service_name", header: "Nom du service" },
					{ field: "identifier", header: "N° d'identifiant" },
					{ field: "created_at", header: "Date de création" },
				],
				globalFilterFields: ["service_name"],
				servicesData: [],
				partnerStore: espacePartenaireStore(),
				authStore: useAuthStore(),
			};
		},
		beforeMount() {
			this.partnerStore.loadServices();
		},
	});


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

	const toast = useToast()

	const schema = yup.object({
		service_name: yup.string().required("Le champ Libellé est requis").label("Libellé"),
		identifier: yup.string().label("N° d'identifiant"),
	});

	const { defineField, handleSubmit, resetForm, errors } = useForm({
		validationSchema: schema,
	});

	const [service_name] = defineField("service_name");
	const [identifier] = defineField("identifier");

	const serviceNameRules = {
		required: "Le nom du service est requis",
	};

	const onSubmit = handleSubmit((values) => {
		const service = {
			health_center_code: "",
			service_name: "",
			identifier: "",
		} as Service;
		service.health_center_code = useAuthStore().getUserInfo.health_center_code;
		service.service_name = values.service_name;
		service.identifier = values.identifier ? values.identifier : "";
		espacePartenaireStore()
			.addNewService(service)
			.finally(() => {
				toast.add({ severity: 'success', summary: 'Message', detail: "Ajout du service effectué avec succès!", life: 8000 });
				addService.value = !addService.value;
				cancel();
			});
	});

	const addService = ref(false);

	const cancel = () => {
		addService.value = false;
		resetForm();
	};

	const newService = () => {
		addService.value = !addService.value;
		resetForm();
	};

return (_ctx: any,_cache: any) => {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Toast),
    _createElementVNode("div", null, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "pt-5 grid" }, [
        _createElementVNode("h1", { class: "page-title" }, "Administration générale")
      ], -1)),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "title-padding m-0" }, [
        _createElementVNode("h1", { class: "espace-header" }, "Informations générales")
      ], -1)),
      (_ctx.partnerStore.getHealthCenter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(PInput, {
                  label: "Nom complet du centre de santé",
                  value: _ctx.partnerStore.getHealthCenter.health_center_name
                }, null, 8, ["value"]),
                _createVNode(PInput, {
                  label: "Téléphone fixe",
                  value: _ctx.partnerStore.getHealthCenter.mobile
                }, null, 8, ["value"]),
                _createVNode(PInput, {
                  label: "District",
                  value: _ctx.partnerStore.getHealthCenter.district
                }, null, 8, ["value"]),
                _createVNode(PInput, {
                  label: "Adresse ligne 1",
                  value: _ctx.partnerStore.getHealthCenter.address_1
                }, null, 8, ["value"]),
                _createVNode(PInput, {
                  label: "Adresse ligne 2",
                  value: _ctx.partnerStore.getHealthCenter.address_2
                }, null, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(PInput, {
                  label: "Trigramme",
                  value: _ctx.partnerStore.getHealthCenter.trigramme
                }, null, 8, ["value"]),
                _createVNode(PInput, {
                  label: "Commune",
                  value: _ctx.partnerStore.getHealthCenter.township
                }, null, 8, ["value"]),
                _createVNode(PInput, {
                  label: "Ville",
                  value: _ctx.partnerStore.getHealthCenter.town
                }, null, 8, ["value"]),
                _createVNode(PInput, {
                  label: "Pays",
                  value: _ctx.partnerStore.getHealthCenter.country
                }, null, 8, ["value"]),
                _createVNode(PInput, {
                  label: "BP",
                  value: _ctx.partnerStore.getHealthCenter.postal_code
                }, null, 8, ["value"])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "title-padding m-0" }, [
        _createElementVNode("h1", { class: "espace-header" }, "Les services")
      ], -1)),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Button, {
          class: "btn service-btn-class",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (newService()))
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" Ajouter un service ")
          ])),
          _: 1
        })
      ]),
      (addService.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("form", {
              onSubmit: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)))
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(PInput, {
                    label: "Libellé",
                    modelValue: _unref(service_name),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(service_name) ? (service_name).value = $event : null)),
                    type: "text",
                    class: _normalizeClass({ 'p-invalid': _unref(errors).service_name }),
                    field: "service_name",
                    rules: serviceNameRules
                  }, null, 8, ["modelValue", "class"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(PInput, {
                    label: "N° d'identifiant",
                    modelValue: _unref(identifier),
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(identifier) ? (identifier).value = $event : null)),
                    field: "identifier"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_Button, {
                  class: "cancel-btn-class mx-2",
                  onClick: cancel
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Annuler ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_Button, {
                  class: "add-agent-btn mx-2",
                  label: "Ajouter",
                  type: "submit"
                })
              ])
            ], 32)
          ]))
        : _createCommentVNode("", true),
      _createVNode(TableView, {
        data: _unref(espacePartenaireStore)().getServices,
        columns: _ctx.columns,
        globalFilterFields: _ctx.globalFilterFields,
        tableTitle: `Liste de services`,
        tableType: "services"
      }, null, 8, ["data", "columns", "globalFilterFields"])
    ])
  ]))
}
}

})