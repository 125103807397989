import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap align-items-center justify-content-between gap-2 p-dialog-header" }
const _hoisted_2 = { class: "text-xl text-700 font-semibold" }
const _hoisted_3 = { class: "p-dialog-content" }
const _hoisted_4 = { class: "header pr-2" }
const _hoisted_5 = { class: "flex justify-content-end" }
const _hoisted_6 = { class: "form-section" }
const _hoisted_7 = { class: "formgrid grid mx-2 mt-6" }
const _hoisted_8 = { class: "field col" }
const _hoisted_9 = { class: "field col" }
const _hoisted_10 = { class: "form-section" }
const _hoisted_11 = { class: "formgrid grid mx-2 mt-6" }
const _hoisted_12 = { class: "field col" }
const _hoisted_13 = { class: "field col" }
const _hoisted_14 = { class: "field grid px-2 py-1" }
const _hoisted_15 = {
  class: "col flex",
  style: {"justify-content":"start"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_PInput = _resolveComponent("PInput")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showModal,
    modal: "",
    style: { width: '75rem' },
    breakpoints: { '1199px': '75vw', '575px': '90vw' }
  }, {
    container: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createVNode(_component_Button, {
          onClick: _ctx.closeModal,
          icon: "pi pi-times",
          rounded: "",
          text: "",
          raised: ""
        }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "separator" }, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              _cache[1] || (_cache[1] = _createTextVNode(" Enregistré depuis : ")),
              _createVNode(_component_Badge, {
                pill: "",
                severity: "warning"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(new Date(
										_ctx.birthStore.birthSelected.created_at
									).toLocaleString("fr-FR", {
										year: "numeric",
										month: "long",
										day: "numeric",
									})), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "section-header" }, "Informations du centre de santé", -1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_PInput, {
                label: "Centre de santé",
                disabled: "",
                value: _ctx.birthStore.birthSelected.health_center
              }, null, 8, ["value"]),
              _createVNode(_component_PInput, {
                label: "Agent de santé",
                value: _ctx.agentName(_ctx.birthStore.birthSelected.midwife),
                disabled: ""
              }, null, 8, ["value"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_PInput, {
                label: "Déclarant",
                value: _ctx.agentName(_ctx.birthStore.birthSelected.declarant),
                disabled: ""
              }, null, 8, ["value"]),
              _createVNode(_component_PInput, {
                label: "Fonction de l'agent",
                value: _ctx.birthStore.birthSelected.agent_function,
                disabled: ""
              }, null, 8, ["value"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "section-header" }, "Informations sur naissance", -1)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_PInput, {
                label: "Nom de la mère",
                value: _ctx.birthStore.birthSelected.mother_last_name,
                disabled: ""
              }, null, 8, ["value"]),
              _createVNode(_component_PInput, {
                label: "Téléphone de la mère",
                value: _ctx.birthStore.birthSelected.mother_phone_number,
                disabled: ""
              }, null, 8, ["value"]),
              _createVNode(_component_PInput, {
                label: "Autre contact 1",
                value: _ctx.birthStore.birthSelected.other_contact_1,
                disabled: ""
              }, null, 8, ["value"]),
              _createVNode(_component_PInput, {
                label: "Autre contact 2",
                value: _ctx.birthStore.birthSelected.other_contact_2,
                disabled: ""
              }, null, 8, ["value"]),
              _createVNode(_component_PInput, {
                label: "Quartier de la mère",
                value: _ctx.birthStore.birthSelected.neighborhood,
                disabled: ""
              }, null, 8, ["value"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_PInput, {
                label: "Prénoms de la mère",
                value: _ctx.birthStore.birthSelected.mother_first_name,
                disabled: ""
              }, null, 8, ["value"]),
              _createVNode(_component_PInput, {
                label: "Heure d'accouchement",
                value: _ctx.birthStore.birthSelected.birth_hour,
                disabled: ""
              }, null, 8, ["value"]),
              _createVNode(_component_PInput, {
                label: "Date d'accouchement",
                value: _ctx.formattedBirthDate,
                disabled: ""
              }, null, 8, ["value"]),
              _createVNode(_component_PInput, {
                label: "Adresse de la mère",
                value: _ctx.birthStore.birthSelected.address,
                disabled: ""
              }, null, 8, ["value"]),
              _createElementVNode("div", _hoisted_14, [
                _cache[4] || (_cache[4] = _createElementVNode("label", {
                  for: "sexe",
                  class: "col mb-2 md:col md:mb-0"
                }, "Cet enfant est-il mort né ?", -1)),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_Checkbox, {
                    modelValue: _ctx.birthStore.birthSelected.stillborn,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.birthStore.birthSelected.stillborn) = $event)),
                    inputId: "stillborn",
                    name: "stillborn",
                    binary: "",
                    disabled: ""
                  }, null, 8, ["modelValue"])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}