<template>
	<div>
		<death-register-modal
			:data="data"
			title="Déclaration de décès"
			:showModal="showModal"
			v-if="showModal"
			@closeModal="closeModal" />
		<div class="my-3 flex grid w-full">
			<table-filter
				:canCreate="false"
				:stats="deathStore.getDeathStats"
				path="/declaration-deces"
				name="un décès" />
		</div>
		<div>
			<table-view
				:data="deathStore.getDeaths"
				:columns="columns"
				:filters="filters"
				:tableTitle="`Liste de déclaration des décès`"
				@onTableRowClicked="showDeathRegisterDetail" />
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
	import TableFilter from "@/components/table/table-filter/table-filter.vue";
	import TableView from "@/components/table/table-view/table-view.vue";
	import DeathRegisterModal from "../../modals/death-register-modal/death-register-modal.vue";
	import { useDeathStore } from "@/store/modules/death";
	import { FilterMatchMode } from "primevue/api";
	export default defineComponent({
		name: "DeathRegistration",
		components: { TableFilter, TableView, DeathRegisterModal },
		data() {
			return {
				columns: [
					{ field: "tracked_code", header: "Code de déclaration" },
					{ field: "health_center", header: "Centre de santé" },
					{ field: "created_at", header: "Date de déclaration" },
					{ field: "last_name_deceased", header: "Nom du defunt" },
					{ field: "declarant", header: "Déclarant" },
					{ field: "document", header: "Document" },
				],
				showModal: false,
				data: null,
				filters: {
					last_name_deceased: {
						value: null,
						matchMode: FilterMatchMode.STARTS_WITH,
					},
				},
				deathStore: useDeathStore(),
			};
		},
		methods: {
			showDeathRegisterDetail(payload: any) {
				this.deathStore.setDeath(payload);
				this.data = payload;
				this.showModal = true;
			},
			closeModal() {
				this.showModal = !this.showModal;
			},
		},
	});
</script>
