import { defineStore } from "pinia";
import { Agent, AgentOption } from "@/models/agent/agent";
import { ProcessManagerService } from "@/services/process-manager.service";
import { useAuthStore } from "@/store/modules/auth";

export const agentStore = defineStore("agent", {
  state: () => ({
    agents: null as Agent[] | null,
    agentSelected: undefined as unknown as Agent,
    isLoading: false,
    agentOptions: [] as AgentOption[],
  }),

  getters: {
    getAgents: (state) => state.agents,
    getAgent: (state) => state.agentSelected,
    getLoading: (state) => state.isLoading,
  },

  actions: {
    loadAgents(): any {
      const health_center_code = useAuthStore().getUserInfo.health_center_code;
      return ProcessManagerService.getInstance(useAuthStore().getAccessToken)
        .loadAgents(health_center_code)
        .then((value) => {
          if (value.data) {
            this.agents = value.data;
            this.formatAgents(value.data);
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    formatAgents(agents: Agent[]) {
      agents.map((agent: Agent) => {
        this.agentOptions.push({
          name: agent.name,
          id: agent.agent_id,
        });
      });
    },
    addAgent(payload: Agent) {
      return ProcessManagerService.getInstance(useAuthStore().getAccessToken)
        .createAgent(payload)
        .then((value) => {
          if (value.data) {
            this.agents?.unshift(value.data);
            this.isLoading = false;
            return value;
          }
        });
    },
    updateAgent(payload: Agent) {
      return ProcessManagerService.getInstance(useAuthStore().getAccessToken)
        .updateAgent(payload)
        .then((value) => {
          const agent = this.agents?.find(
            (element) => element.agent_id == payload?.agent_id
          );
          if (agent) {
            agent.name = payload.name;
            agent.mobile = payload.mobile;
            agent.function = payload.function;
            agent.service_id = payload.service_id;
            agent.sex = payload.sex;
            agent.identity_id = payload.identity_id;
            const index = this.agents?.indexOf(agent);
            if (this.agents && index) this.agents[index] = agent;
          }
          this.isLoading = false;
          return value;
        });
    },
    setAgent(payload: Agent) {
      this.agentSelected = payload;
    },
    setLoading() {
      this.isLoading = true;
    },
  },
});
