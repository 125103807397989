import { defineStore } from "pinia";
import { BirthDeclaration } from "@/models/declaration/birth-declaration";
import { ProcessManagerService } from "@/services/process-manager.service";
import { useAuthStore } from "@/store/modules/auth";
import { filterMixin } from "@/mixins/filter.mixins";
import { Statistic } from "@/models/statistic/statistic";

export const useBirthStore = defineStore("birth", {
  state: () => ({
    births: null as BirthDeclaration[] | null,
    birthSelected: undefined as unknown as BirthDeclaration,
    birthStats: {
      currentMonth: 0,
      previousMonth: 0,
      currentYear: 0
    } as Statistic
  }),

  getters: {
    getBirths: (state) => state.births,
    getBirth: (state) => state.birthSelected,
    getBirthStats: (state) => state.birthStats,
  },

  actions: {
    sendBirthDeclaration(payload: Array<BirthDeclaration>): Promise<any> {
      return ProcessManagerService.getInstance(useAuthStore().getAccessToken)
        .sendBirthDeclaration(payload)
        .then((value) => {
          if (value.data) {
            if(!this.births) this.births = [];
            this.births?.unshift(value.data[0]);
            this.birthStats = filterMixin.methods.countByPeriod(this.births)
            return value.data[0];
          } else return null;
        });
    },
    loadBirthDeclarations() {
      const health_center_code = useAuthStore().getUserInfo.health_center_code;
      ProcessManagerService.getInstance(useAuthStore().getAccessToken)
        .loadBirthDeclarations(health_center_code)
        .then((value) => {
          if (value.data) {
            this.births = value.data;
            this.birthStats = filterMixin.methods.countByPeriod(value.data)
          }
        });
    },
    setBirth(payload: BirthDeclaration) {
      this.birthSelected = payload;
    },
  },
});