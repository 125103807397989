<template>
	<Dialog
		:visible="showModal"
		modal
		:style="{ width: '75rem' }"
		:breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
		<template #container>
			<div
				class="flex flex-wrap align-items-center justify-content-between gap-2 p-dialog-header">
				<span class="text-xl text-700 font-semibold">{{ title }}</span>
				<Button
					@click="closeModal"
					icon="pi pi-times"
					rounded
					text
					raised />
			</div>
			<div class="p-dialog-content">
				<div class="header pr-2">
					<div>
						<div class="separator"></div>
						<div class="flex justify-content-end">
							Enregistré depuis :
							<Badge
								pill
								severity="warning"
								>{{
									new Date(
										deathStore.deathSelected.created_at
									).toLocaleString("fr-FR", {
										year: "numeric",
										month: "long",
										day: "numeric",
									})
								}}
							</Badge>
						</div>
					</div>
				</div>
				<div class="form-section">
					<div class="section-header">Informations du centre de santé</div>
					<div
						class="formgrid grid mx-2 mt-6">
						<div class="field col">
							<PInput
								label="Centre de santé"
								:value="deathStore.deathSelected.health_center"
								disabled />
							<PInput
								label="Agent de santé"
								:value="agentName(deathStore.deathSelected.chief_service)"
								disabled />
						</div>
						<div class="field col justify-content-bottom">
							<PInput
								label="Déclarant"
								:value="agentName(deathStore.deathSelected.declarant)"
								disabled />
						</div>
					</div>
				</div>
				<div class="form-section">
					<div class="section-header">Informations sur le demandeur</div>
					<div
						class="formgrid grid mx-2 mt-6">
						<div class="field col">
							<PInput
								label="Nom du demandeur"
								:value="deathStore.deathSelected.applicant_name"
								disabled />
							<PInput
								label="Lien de parenté"
								:value="deathStore.deathSelected.applicant_relationship"
								disabled />
						</div>
						<div class="field col">
							<PInput
								label="N° d'identité"
								:value="deathStore.deathSelected.applicant_identifier"
								disabled />
							<PInput
								label="N° de téléphone"
								:value="deathStore.deathSelected.applicant_telephone"
								disabled />
						</div>
					</div>
				</div>
				<div class="form-section">
					<div class="section-header">Informations sur naissance</div>
					<div
						class="formgrid grid mx-2 mt-6">
						<div class="field col">
							<PInput
								label="Nom du defunt"
								:value="deathStore.deathSelected.last_name_deceased"
								disabled />
							<PInput
								label="Age du defunt"
								:value="deathStore.deathSelected.age_deceased"
								disabled />
							<PInput
								label="Téléphone contact"
								:value="deathStore.deathSelected.contact_phone_number"
								disabled />
							<PInput
								label="Autre contact"
								:value="deathStore.deathSelected.other_contact"
								disabled />
							<PInput
								label="Quartier du defunt"
								:value="deathStore.deathSelected.neighborhood"
								disabled />
						</div>
						<div class="field col">
							<PInput
								label="Prénoms du defunt"
								:value="deathStore.deathSelected.first_name_deceased"
								disabled />
							<PInput
								label="Fonction du defunt"
								:value="deathStore.deathSelected.function_of_deceased"
								disabled />
							<PInput
								label="Cause d'admission"
								:value="deathStore.deathSelected.reason_for_admission"
								disabled />
							<PInput
								label="Date de décès"
								:value="formattedBirthDate"
								disabled />
							<PInput
								label="Adresse du defunt"
								:value="deathStore.deathSelected.address"
								disabled />
						</div>
					</div>
				</div>
			</div>
		</template>
	</Dialog>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
	import PInput from "@/components/forms/p-input.vue";
	import { useDeathStore } from "@/store/modules/death";
	import { agentStore } from "@/store/modules/agent";
	import { dataMixin } from "@/mixins/data.mixins";
	import { dateMixin } from "@/mixins/date.mixins";
	export default defineComponent({
		name: "DeathRegisterModal",
		props: ["title", "showModal", "data"],
		mixins: [dataMixin, dateMixin],
		components: {
			PInput,
		},
		data() {
			return {
				deathStore: useDeathStore(),
				agentStore: agentStore(),
				formattedBirthDate: "",
			};
		},
		setup(props, { emit }) {
			const closeModal = () => {
				emit("closeModal");
			};

			return {
				closeModal,
			};
		},
		beforeMount() {
			this.formattedBirthDate = this.formatDate(
				this.deathStore.deathSelected.death_date
			);
		},
	});
</script>

<style src="./death-register-modal.scss" lang="scss" scoped />
