<template>
	<div class="w-full">
		<Card>
			<template #content>
				<div class="flex formgrid">
					<div class="col align-self-center">
						<div
							v-if="path"
							class="mt-1">
							<Button
								class="files-btn-class"
								@click="$router.push({ path: path })">
								Déclarer {{ name }}
							</Button>
						</div>
						<div
							v-else
							class="mt-1">
							<Button
								class="files-btn-class"
								@click="declaration()">
								Déclarer {{ name }}
							</Button>
						</div>
					</div>
					<div class="col" v-if="path">
						<Card class="stat-card">
							<template #content>
								<div class="flex formgrid align-items-center justify-content-between">
									<div>
										<i class="pi pi-circle-fill mr-2" style="color: green"></i>
										<span class="text-lg text-800 font-medium">Mois actuel </span>
									</div>
									<div class="text-lg text-800 font-medium">
										{{ stats.currentMonth }}
									</div>
								</div>
							</template>
						</Card>
						<Card class="mt-2 stat-card">
							<template #content>
								<div class="flex formgrid align-items-center justify-content-between">
									<div>
										<i class="pi pi-circle-fill mr-2" style="color: red"></i>
										<span class="text-lg text-800 font-medium">Mois précédent </span>
									</div>
									<div class="text-lg text-800 font-medium">
										{{ stats.previousMonth }}
									</div>
								</div>
							</template>
						</Card>
					</div>
					<div class="col" v-if="path">
						<Card class="stat-card">
							<template #content>
								<div class="flex formgrid align-items-center justify-content-between">
									<div>
										<i class="pi pi-th-large mr-2" style="color: red"></i>
										<span class="text-lg text-800 font-medium">Année en cours </span>
									</div>
									<div class="text-lg text-800 font-medium">
										{{ stats.currentYear }}
									</div>
								</div>
							</template>
						</Card>
					</div>
				</div>
			</template>
		</Card>
	</div>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: ["path", "name", "stats"],
	})
</script>

<script setup lang="ts">
	import { ref, defineEmits } from "vue";

	const emits = defineEmits(["searchText", "declarationForm"]);

	const declarationForm = ref(false);

	const declaration = () => {
		emits("declarationForm", !declarationForm.value);
		declarationForm.value = !declarationForm.value;
	};
</script>

<style scoped src="./table-filter.scss" lang="scss" />
