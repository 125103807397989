import { defineStore } from "pinia";
import AuthService from "@/services/auth.service";
import {
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from "oidc-client-ts";
import {
  keycloackAuthorityUrl,
  keycloackClientIdUrl,
  keycloackRealm,
  siteUrl,
} from "@/app.config";
import { Profile } from "@/models/security/profile";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    authenticated: false,
    connectedUser: null as unknown as User,
    accessToken: "",
    _userManager: new UserManager({
      authority: keycloackAuthorityUrl,
      client_id: keycloackClientIdUrl,
      redirect_uri: siteUrl + "/login-callback",
      response_type: "code",
      scope: "openid profile email",
      post_logout_redirect_uri: siteUrl + "/login",
      automaticSilentRenew: true,
      silent_redirect_uri: siteUrl + "/silent-renew.html",
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        issuer: keycloackAuthorityUrl,
        authorization_endpoint:
          `${keycloackAuthorityUrl}/realms/${keycloackRealm}/protocol/openid-connect/auth`,
        token_endpoint:
          `${keycloackAuthorityUrl}/realms/${keycloackRealm}/protocol/openid-connect/token`,
        userinfo_endpoint:
          `${keycloackAuthorityUrl}/realms/${keycloackRealm}/protocol/openid-connect/userinfo`,
        end_session_endpoint:
          `${keycloackAuthorityUrl}/realms/${keycloackRealm}/protocol/openid-connect/logout`,
      },
    } as UserManagerSettings),
  }),

  getters: {
    getAccessToken: (state) => {
      return state.connectedUser?.access_token;
    },
    isAuthenticated: (state) => state.authenticated,
    getUserInfo: (state) => {
      return state.connectedUser?.profile as Profile;
    },
  },

  actions: {
    login(redirectUri: string) {
      return this._userManager.signinRedirect();
    },

    async onLoginCallback(): Promise<User | void> {
      return this._userManager.signinCallback();
    },

    async onSilentRenewCallback(): Promise<void> {
      return this._userManager.signinSilentCallback();
    },

    async onSignInSilent(): Promise<User | null> {
      return this._userManager.signinSilent();
    },

    async getProfile() {
      const authService: AuthService = new AuthService(
        <UserManager>this._userManager
      );
      await authService.getProfile().then((response) => {
        this.accessToken = response.access_token;
        this.authenticated = true;
      });
    },

    removeUserData() {
      return this._userManager.removeUser();
    },

    logout() {
      this.removeUserData().then(() => {
        this._userManager.signoutRedirect();
      });
    },

    getConnectedUser() {
      return this._userManager.getUser();
    },

    setConnectedUser(user: User) {
      this.connectedUser = user;
    },
  },
});
