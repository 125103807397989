<template>
    <div class="flex-auto" :class="inline ? 'input-group' : ''">
      <!-- <label class="label" v-if="label">{{ label }}</label> -->
      <Dropdown 
        v-model="value" 
        :editable="editable" 
        :options="options" 
        :optionLabel="optionLabel" 
        :optionValue="optionValue"
        :placeholder="placeholder" 
        :showClear="showClear"
        @change="onChange"
        style="width: 200px;"
      />
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, watch } from 'vue';
  import Dropdown from 'primevue/dropdown';
  
  export default defineComponent({
    name: 'PSelect',
    components: { Dropdown },
    props: {
      label: String,
      placeholder: String,
      selected: [String, Number, Object],
      optionLabel: String,
      options: Array,
      editable: Boolean,
      optionValue: String,
      inline: Boolean,
      showClear: Boolean
    },
    emits: ['update:modelValue', 'onChange'],
    data() {
      return {
        value: this.selected,
      };
    },
    watch: {
      value(newValue) {
        this.$emit('update:modelValue', newValue);
      },
      selected(newValue) {
        this.value = newValue;
      },
    },
    methods: {
      onChange() {
        this.$emit('onChange', this.value);
      },
    },
  });
  </script>
  